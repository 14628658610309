<template>
  <div>
    <v-card>
      <v-card-title>
        Total Users
        <v-spacer />
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="statusData" item-key="name" :search="search"></v-data-table>
    </v-card>
    <div class="mt-10">
      <v-btn
        class="ml-3"
        color="primary"
        @click="$router.push({ name: 'MobileProjectCodeIndex' })"
      >Back</v-btn>
    </div>
  </div>
</template>


<script>
import RestResource from "../../services/dataServiceMobile.js";
const service = new RestResource();
export default {

  data() {
    return {
      headers: [
        { text: "Project Code", value: "ProjectCode", sortable: true },
        { text: "Project Name", value: "ProjectName", sortable: true },
        { text: "Total Users", value: "TotalUsers", sortable: true }
      ],
      statusData: [],
      search: "",
      chartData: null,
      series: [{
        data: []
      }],
    };
  },

  mounted() {
    this.loadStatus();

  },

  methods: {
    async loadStatus() {
      this.$setLoader()
     await service.getUsersByProjectCode().then(res => {
        this.statusData = res.data;
        this.$disableLoader()
      });
    },
  }
};
</script>

<style  scoped>
h2 {
  text-align: center;
}
</style>